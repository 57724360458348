<template>
  <div class="flex-centent mobile-login">
    <!-- <a-spin :spinning="spinning"> -->
    <div class="login">
      <a-spin :spinning="spinning">
        <a-form
          class="user-layout-login"
          :model="formState"
          @finish="handleFinish"
        >
          <div class="inviteHead">邀请加入</div>

          <div class="logo">
            <img src="@/assets/logo2.png" />
          </div>
          <div class="failedInfo">跳转中...</div>
        </a-form>
      </a-spin>
    </div>
    <!-- </a-spin> -->
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import { defineComponent } from 'vue'
// import { Decrypt, Encrypt } from '@/utils/secret.js'
export default defineComponent({
  data () {
    return {
      before: 'inviteKey=39fddef5-9f6b-52f0-c569-e217c2e33537&way=1',
      after: '',
      url: '',
      aimUrl: '',
      REQUEST: {},
      data: '',
    }
  },
  created () {
    this.url = document.URL
    var arrUrl = this.url.split('/')
    this.before = arrUrl.pop()
    // this.after = Decrypt(this.before)
    var transform = function (str) {
      var REQUEST = {}
      // 截取数据字符串
      str = str.toString() + ''
      const data = str.slice(0, str.length)
      // 将数据字符串表现为数组
      const aParams = data.substr(0).split('&')
      for (let i = 0; i < aParams.length; i++) {
        // 数据属性与数值分离
        var aParam = aParams[i].split('=')
        // 使用属性括号进行属性赋值
        REQUEST[aParam[0]] = aParam[1]
      }
      return REQUEST
    }
    const obj = {
      shortKey: this.before,
    }
    this.REQUEST = obj
    setTimeout(() => {
      this.$message.success('正在跳转')
      this.$router.push({ path: '/account/InviteMobile', query: this.REQUEST })
    }, 2000)
  },
})
</script>
<style lang="scss" scoped>
@import "@/assets/common.scss";
.mobile-login {
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  background: rgb(233, 233, 233);
  .login {
    height: 90vh;
    padding: 5vh 5vw;
    border-radius: 2%;
    background-color: #fff;
  }
  .inviteHead {
    width: 334px;
    height: 120px;
    font-size: 18px;
    text-align: center;
    line-height: 100px;
    font-family: "Microsoft YaHei";
    color: rgb(255, 81, 110);
  }
  .user-layout-login {
    height: 80vh;
  }
  .logo {
    margin: 15px 0;
  }
  .title {
    margin: 0 10px;
    font-size: 20px;
    color: #134ccf;
    font-weight: 700;
  }
  .name {
    margin-top: 6vh;
  }
  .getCaptcha {
    height: 40px;
  }
  .login-button {
    width: 336px;
    height: 40px;
  }
}
</style>
